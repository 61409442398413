import React, { useState } from "react";
import { Menu } from "antd";
import {
  RightOutlined,
  LeftOutlined,
  UploadOutlined,
  GroupOutlined,
  PicCenterOutlined,
  DollarOutlined,
  TeamOutlined,
  TagOutlined,
} from "@ant-design/icons";
import Link from "next/link";
import st from "./st.module.scss";
const { Item, SubMenu } = Menu;

interface NavigationProps {
  appId?: string;
}

const Navigation: React.FunctionComponent<NavigationProps> = ({ appId }) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className={st.Navigation}>
      <Menu mode="inline" inlineCollapsed={collapsed} selectable={false} style={{ height: "100%" }}>
        <Item onClick={() => setCollapsed(!collapsed)}>
          <div>{collapsed ? <RightOutlined /> : <LeftOutlined />}</div>
        </Item>
        <Item key={"stories"} icon={<GroupOutlined />}>
          <Link href={`/app-id/${appId}/stories`}>
            <a href={`/app-id/${appId}/stories`}>Stories</a>
          </Link>
        </Item>
        <Item key={"items"} icon={<PicCenterOutlined />}>
          <Link href={`/app-id/${appId}/items`}>
            <a href={`/app-id/${appId}/items`}>Items</a>
          </Link>
        </Item>
        <SubMenu title={"Import"} icon={<UploadOutlined />} key={"importGroup"}>
          <Item key={"/app-id/[appId]/items/import"} icon={<UploadOutlined />}>
            <Link href={`/app-id/${appId}/items/import`}>
              <a href={`/items/import`}>Items</a>
            </Link>
          </Item>
          <Item key={"/app-id/[appId]/items/import/mapping"} icon={<UploadOutlined />}>
            <Link href={`/app-id/${appId}/items/import/mapping`}>
              <a href={`/items/import/mapping`}>Item Variants</a>
            </Link>
          </Item>
        </SubMenu>
        <Item key={"/app-id/[appId]/accounts"} icon={<TeamOutlined />}>
          <Link href={`/app-id/${appId}/accounts`}>
            <a href={`/accounts`}>Accounts</a>
          </Link>
        </Item>
        <Item key={"/app-id/[appId]/orders"} icon={<DollarOutlined />}>
          <Link href={`/app-id/${appId}/orders?filterText=&limit=10&page=1&status=`}>
            <a href={`/orders?filterText=&limit=10&page=1&status=`}>Orders</a>
          </Link>
        </Item>
        <Item key={"/app-id/[appId]/discountCodes"} icon={<TagOutlined />}>
          <Link href={`/app-id/${appId}/discountCodes?limit=10&page=1&active=true&type=all`}>
            <a href={`/discountCodes?limit=10&page=1&active=true&type=all`}>Campaigns</a>
          </Link>
        </Item>
      </Menu>
    </div>
  );
};

export default Navigation;
