import React, { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import Link from "next/link";

import { UserContext } from "context/user";

import { useCurrentAppId } from "hooks/useCurrentAppId";
import { PageHeader, Select } from "antd";

import st from "./st.module.scss";
const { Option } = Select;

export function NavHeader(): JSX.Element {
  const [didPrefetchShopItemUrls, setDidPrefetchShopItemUrls] = useState<boolean>(false);
  const { user, logout } = useContext(UserContext);
  const { appId } = useCurrentAppId();
  const router = useRouter();
  const shops = (user?.vendorConfigs || [])
    .map(({ appId, brandConfig }) => ({
      appId,
      name: brandConfig.title,
    }))
    .sort(function (a, b) {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });

  useEffect(() => {
    if (!didPrefetchShopItemUrls) {
      shops.forEach((shop) => router.prefetch(`/app-id/${shop.appId}/items`));
      setDidPrefetchShopItemUrls(true);
    }
  }, [didPrefetchShopItemUrls, shops, router]);

  const ShopIcon = ({ name }: { name: string }) => <div className={st.Header__Logo}>{name}</div>;
  const onShopSelect = (selectedAppId: string) => {
    if (selectedAppId !== appId) {
      router.push(`/app-id/${selectedAppId}/items`);
    }
  };

  const ShopSelect = () =>
    user && (
      <Select<string> className={st.Header__ShopSelect} value={appId} onSelect={onShopSelect}>
        {shops.map((shop) => (
          <Option key={shop.appId} value={shop.appId}>
            <ShopIcon {...shop} />
          </Option>
        ))}
      </Select>
    );

  const Extras = () => (
    <div className={st.Header__Extra}>
      {user && (
        <Link href={"/sign-in"}>
          <a href={"/sign-in"} onClick={logout}>
            Logout
          </a>
        </Link>
      )}
    </div>
  );

  return <PageHeader className={st.Header} title={ShopSelect()} extra={Extras()} />;
}
